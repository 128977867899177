.offer-menu-container {
  padding-right: 0 !important;
  padding-top: 2rem;
}

.offer-menu {
  width: 100%;
  background-color: $sidebar-dark-bg;
}

.offer-menu-item {
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
  height: 3rem;
  background-color: transparent;
  border: none;
  color: $sidebar-dark-menu-item-color;
  width: 100%;

  &.active {
    color: $sidebar-dark-menu-item-active-color;
    background-color: $sidebar-dark-menu-item-active-bg;
  }
}
