.ql-editor {
  min-height: 40vh !important;
}

.ql-snow .ql-editor img {
  max-height: 5rem;
  align-self: center;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
