//
// _buttons.scss
//

button,
a {
  outline: none !important;
}

// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

.btn-dark {
  color: $gray-200 !important;
}

.btn-outline-dark {
  &:hover {
    color: $gray-200 !important;
  }
}

.text-only-button {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  padding: 0;
}

.text-only-button:hover {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  color: $primary;
}

.text-only-button:focus {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  color: $primary;
}

.text-only-button-disabled {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  opacity: 0.65;
  padding: 0;
}

.text-only-button:hover {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
