.gallery-file-modal-no-files {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery-files-list {
  border-width: 0.5px;
  border-color: hsl(0, 0%, 80%);
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  height: 70vh;
  border-radius: 0 !important;
  align-content: flex-start;
}

.gallery-files-list-sm {
  border-width: 0.5px;
  border-color: hsl(0, 0%, 80%);
  display: flex;
  flex-wrap: wrap;
  border-radius: 0 !important;
  align-content: flex-start;
}

.gallery-files-list-box {
  border-width: 1px;
  border-color: hsl(0, 0%, 80%);
  border-style: solid;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery-file-list-file {
  height: 3.2rem;
  border: hsl(0, 0%, 80%) 0.1rem solid;
  padding: 0 !important;
  border-radius: 0 !important;
  padding: 2px;
  align-items: center;
  display: flex;
  width: 100%;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: inline-block;
    text-align: left;
  }
}

.gallery-file-list-file-selected {
  border-color: $primary !important;
  border-width: 0.1rem;
}

.gallery-file-list-file-image {
  height: 3rem;
  width: 3rem;
}