.react-datepicker__header {
  background-color: $datepicker-background-color !important;
}

.react-datepicker__input-container input:focus {
  border-color: rgba($primary, 0.6) !important;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__day--keyboard-selected {
  background: $primary;
}

.react-datepicker__day--keyboard-selected {
  background: $primary !important;
  color: $button-text-color !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background: $primary !important;
  border-right-color: $datepicker-border-color;
  border-right-width: 0.1rem;
  border-right-style: solid;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  border-right-color: $datepicker-border-color;
  border-right-width: 0.1rem;
  border-right-style: solid;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background: $primary !important;
  border-radius: $border-radius !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: $primary !important;
  border-right-color: $datepicker-border-color;
  border-right-width: 0.1rem;
  border-right-style: solid;
}

.react-datepicker__triangle {
  left: 30px !important;
  transform: none !important;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-bottom-width: 0.5rem;
  border-bottom-style: solid;
  border-bottom-color: $datepicker-background-color !important;
  z-index: 1;
}

.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-top-color: $datepicker-border-color !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  border-bottom-width: 0.5rem;
  border-bottom-style: solid;
  border-bottom-color: $datepicker-background-color !important;
  z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-bottom-color: $datepicker-border-color !important;
}
