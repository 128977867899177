// styling variables
$city-primary:rgba(231, 60, 115, 1);
$city-primary-button-text: white;
$city-secondary: rgba(238, 119, 82, 1);
$city-secondary-button-text: white;

$city-sidebar-dark-bg: #ebeded;
$city-sidebar-dark-menu-item-color: hsl(213, 13%, 31%);
$city-sidebar-dark-menu-sub-item-color: hsl(213, 13%, 31%);
$city-sidebar-dark-menu-item-icon-color: hsl(213, 13%, 31%);
$city-sidebar-dark-menu-item-hover-color: rgba(231, 60, 115, 0.9);
$city-sidebar-dark-menu-item-active-color: rgb(250, 250, 250);
$city-sidebar-dark-menu-item-active-bg: rgba(231, 60, 115, 1);

$city-header-bg: linear-gradient(45deg, rgba(238, 119, 82, 1) 0%, rgba(231, 60, 126, 1) 33%, rgba(35, 166, 213, 1) 66%, rgba(35, 213, 171, 1) 100%);
$city-header-item-color: white;

// end variables

$city-primary-25: adjust-color($color: $city-primary,
  $alpha: -0.75,
);
$city-secondary-25: adjust-color($color: $city-secondary,
  $alpha: -0.75,
);


.city {
  .bg-primary {
    background-color: $city-primary !important
  }

  a.bg-primary:hover,
  a.bg-primary:focus,
  button.bg-primary:hover,
  button.bg-primary:focus {
    background-color: shade-color($city-primary, 10%) !important
  }

  .btn-primary {
    color: $city-primary-button-text;
    background-color: $city-primary;
    border-color: $city-primary-25;
  }

  .btn-primary:hover {
    color: $city-primary-button-text;
    background-color: shade-color($city-primary, 10%);
    border-color: $city-primary;
  }

  .btn-primary:focus,
  .btn-primary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem shade-color($city-primary, 10%);
    box-shadow: 0 0 0 0.2rem shade-color($city-primary, 10%);
  }

  .btn-primary.disabled,
  .btn-primary:disabled {
    color: $city-primary-button-text;
    background-color: shade-color($city-primary, 60%);
    border-color: shade-color($city-primary, 60%);
  }

  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled).active,
  .show>.btn-primary.dropdown-toggle {
    color: $city-primary-button-text;
    background-color: shade-color($city-primary, 60%);
    border-color: shade-color($city-primary, 60%);
  }

  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show>.btn-primary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem shade-color($city-primary, 10%);
    box-shadow: 0 0 0 0.2rem shade-color($city-primary, 10%);
  }

  .btn-secondary {
    color: $city-secondary-button-text;
    background-color: $city-secondary;
    border-color: $city-secondary-25;
  }

  .btn-secondary:hover {
    color: $city-secondary-button-text;
    background-color: shade-color($city-secondary, 10%);
    border-color: $city-secondary;
  }

  .btn-secondary:focus,
  .btn-secondary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem shade-color($city-secondary, 10%);
    box-shadow: 0 0 0 0.2rem shade-color($city-secondary, 10%);
  }

  .btn-secondary.disabled,
  .btn-secondary:disabled {
    color: $city-secondary-button-text;
    background-color: shade-color($city-secondary, 60%);
    border-color: shade-color($city-secondary, 60%);
  }

  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show>.btn-secondary.dropdown-toggle {
    color: $city-secondary-button-text;
    background-color: shade-color($city-secondary, 60%);
    border-color: shade-color($city-secondary, 60%);
  }

  .btn-secondary:not(:disabled):not(.disabled):active:focus,
  .btn-secondary:not(:disabled):not(.disabled).active:focus,
  .show>.btn-secondary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem shade-color($city-secondary, 10%);
    box-shadow: 0 0 0 0.2rem shade-color($city-secondary, 10%);
  }

  .btn-outline-primary {
    color: $city-primary !important;
    border-color: $city-primary !important;
  }

  .btn-outline-primary:hover {
    color: $city-primary-button-text;
    background-color: $city-primary-25;
    border-color: $city-primary !important;
  }

  .btn-outline-primary:focus,
  .btn-outline-primary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem adjust-color($color: $city-primary, $alpha: -0.9);
    box-shadow: 0 0 0 0.2rem adjust-color($color: $city-primary, $alpha: -0.9);
  }

  .btn-outline-primary.disabled,
  .btn-outline-primary:disabled {
    color: adjust-color($color: $city-primary, $alpha: -0.5);
    background-color: transparent;
  }

  .btn-outline-primary:not(:disabled):not(.disabled):active,
  .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show>.btn-outline-primary.dropdown-toggle {
    color: $city-primary-button-text;
    background-color: $city-primary-25;
    border-color: $city-primary !important;
  }

  .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
  .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
  .show>.btn-outline-primary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem adjust-color($color: $city-primary, $alpha: -0.9);
    box-shadow: 0 0 0 0.2rem adjust-color($color: $city-primary, $alpha: -0.9);
  }

  .btn-outline-secondary {
    color: $city-secondary !important;
    border-color: $city-secondary !important;
  }

  .btn-outline-secondary:hover {
    color: $city-secondary-button-text;
    background-color: $city-secondary-25;
    border-color: $city-secondary !important;
  }

  .btn-outline-secondary:focus,
  .btn-outline-secondary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem adjust-color($color: $city-secondary, $alpha: -0.9);
    box-shadow: 0 0 0 0.2rem adjust-color($color: $city-secondary, $alpha: -0.9);
  }

  .btn-outline-secondary.disabled,
  .btn-outline-secondary:disabled {
    color: adjust-color($color: $city-secondary, $alpha: -0.9);
    background-color: transparent;
  }

  .btn-outline-secondary:not(:disabled):not(.disabled):active,
  .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show>.btn-outline-secondary.dropdown-toggle {
    color: $city-secondary-button-text;
    background-color: $city-secondary-25;
    border-color: $city-secondary !important;
  }

  .btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
  .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
  .show>.btn-outline-secondary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem adjust-color($color: $city-secondary, $alpha: -0.9);
    box-shadow: 0 0 0 0.2rem adjust-color($color: $city-secondary, $alpha: -0.9);
  }

  .form-control:focus {
    border-color: adjust-color($color: $city-primary, $alpha: -0.5) !important;
    -webkit-box-shadow: 0 0 0 0.2rem adjust-color($color: $city-primary, $alpha: -0.8) !important;
    box-shadow: 0 0 0 0.2rem adjust-color($color: $city-primary, $alpha: -0.8) !important;
  }

  .custom-file-input:focus~.custom-file-label {
    border-color: adjust-color($color: $city-primary, $alpha: -0.5);
    -webkit-box-shadow: 0 0 0 0.2rem adjust-color($color: $city-primary, $alpha: -0.8);
    box-shadow: 0 0 0 0.2rem adjust-color($color: $city-primary, $alpha: -0.8);
  }

  .custom-control-input:checked~.custom-control-label::before {
    color: $city-primary-button-text;
    border-color: $city-primary !important;
    background-color: $city-primary !important;
  }

  .custom-control-input:focus~.custom-control-label::before {
    -webkit-box-shadow: 0 0 0 0.2rem adjust-color($color: $city-primary, $alpha: -0.8);
    box-shadow: 0 0 0 0.2rem adjust-color($color: $city-primary, $alpha: -0.8);
  }

  .custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: adjust-color($color: $city-primary, $alpha: -0.5);
  }

  .custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
    border-color: $city-primary !important;
    background-color: $city-primary !important;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    color: $city-primary-button-text;
    background-color: $city-primary !important;
  }

  .custom-range::-webkit-slider-thumb {
    background-color: $city-primary !important;
  }

  .custom-range::-moz-range-thumb {
    background-color: $city-primary !important;
  }

  .custom-range::-ms-thumb {
    background-color: $city-primary !important;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show>.nav-link {
    color: $city-primary-button-text !important;
    background-color: $city-primary !important;
  }

  .select2-container--default {
    .select2-results__option--highlighted[aria-selected] {
      background-color: $city-primary !important;
    }

    .select2-results__option[aria-selected="true"] {
      &:hover {
        background-color: $city-primary !important;
        color: $city-primary-button-text !important;
      }
    }
  }

  .react-datepicker__input-container input:focus {
    border-color: rgba($city-primary, 0.6) !important;
  }

  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__day--keyboard-selected {
    background: $city-primary;
  }

  .react-datepicker__day--keyboard-selected {
    background: $city-primary !important;
    color: $city-primary-button-text !important;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
    background: $city-primary !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    background: $city-primary !important;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background: $city-primary !important;
  }

  .dropzone {
    color: $city-primary !important;
  }

  .dropzone .dz-preview.dz-image-preview,
  .dropzone .dz-preview.dz-file-preview {
    color: $city-primary !important;

    .preview-container {
      i {
        color: $city-primary;
      }
    }

    .remove {
      color: $city-primary !important;
    }

    .dz-details {
      color: $city-primary !important;
    }

    .dz-progress {
      .dz-upload {
        background: $city-primary !important;
      }
    }
  }

  .gallery-file-list-file-selected {
    border-color: $city-primary !important;
  }

  .nav-tabs-custom {
    .nav-item {
      .nav-link {
        &::after {
          content: "";
          background: $city-primary;
        }

        &.active {
          color: $city-primary;
        }
      }
    }
  }

  .nav-link:hover,
  .nav-link:focus {
    color: $city-primary;
  }

  .text-only-button:hover {
    color: $city-primary;
  }

  .text-only-button:focus {
    color: $city-primary;
  }

  .rc-switch-checked:not(.rc-switch-disabled) {
    background-color: $city-primary !important;
    border: 1px solid $city-primary !important;
  }

  .rc-switch:focus {
    box-shadow: 0 0 0 2px $city-primary-25 !important;
  }

  .loading {
    border: 2px solid rgba($city-primary, 0.2);
    border-top-color: $city-primary;
  }

  .text-primary {
    color: $city-primary !important;
  }

  a.text-primary:hover,
  a.text-primary:focus {
    color: shade-color($city-primary, 10%) !important;
  }

  .text-secondary {
    color: $city-secondary !important;
  }

  a.text-secondary:hover,
  a.text-secondary:focus {
    color: shade-color($city-secondary, 10%) !important;
  }

  .page-link {
    color: $city-primary-button-text;
    border-color: $city-primary;

    &:hover {
      color: shade-color($city-primary-button-text, 10%);
      border-color: $city-primary;
    }

    &:focus {
      color: shade-color($city-primary-button-text, 10%);
      outline: adjust-color($color: $city-primary, $alpha: -0.8);
      box-shadow: 0 0 0 1px adjust-color($color: $city-primary, $alpha: -0.8);
    }
  }

  .page-item {
    &.active .page-link {
      color: $city-primary-button-text;
      @include gradient-bg($city-primary);
      border-color: $city-primary;
    }
  }

  #page-topbar {
    background: $city-header-bg !important;
  }

  .app-search {
    span {
      color: $city-header-item-color;
    }
  }

  .header-item {
    color: $city-header-item-color;

    &:hover {
      color: $city-header-item-color;
    }
  }

  .noti-icon {
    i {
      color: $city-header-item-color;
    }
  }

  // sidebar

  .vertical-collpsed {
    .vertical-menu {
      #sidebar-menu {
        >ul {
          >li {
            &:hover {
              >a {
                color: $city-primary !important;

                i {
                  color: $city-primary !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .vertical-menu {
    background: $city-sidebar-dark-bg !important;
  }

  #sidebar-menu {
    ul {
      li.opened-only {
        color: $city-sidebar-dark-menu-item-active-color !important;
        background-color: $city-sidebar-dark-bg !important;

        >a {
          color: $city-sidebar-dark-menu-item-active-color !important;
          background-color: $city-sidebar-dark-bg !important;

          i {
            color: $city-sidebar-dark-menu-item-active-color !important;
          }
        }

        >a.mm-active {
          color: $city-sidebar-dark-menu-item-active-color !important;
          background-color: $city-sidebar-dark-bg !important;

          &:hover {
            background-color: darken($city-sidebar-dark-bg, 2%) !important;
          }

          i {
            color: $city-sidebar-dark-menu-item-active-color !important;
          }

          span {
            color: $city-sidebar-dark-menu-item-active-color !important;

            &:hover {
              color: $city-sidebar-dark-menu-item-color !important;
            }
          }
        }
      }

      li {
        a.mm-active {
          color: $city-sidebar-dark-menu-item-active-color !important;

          i {
            color: $city-sidebar-dark-menu-item-active-color !important;
          }

          &:hover {
            color: adjust-color($color: $city-sidebar-dark-menu-item-color, $red: 150, $blue: 150, $green: 150) !important;
            background-color: darken($city-sidebar-dark-menu-item-active-bg, 2%) !important;

            i {
              color: adjust-color($color: $city-sidebar-dark-menu-item-color, $red: 150, $blue: 150, $green: 150) !important;
            }
          }

          span {
            color: $city-sidebar-dark-menu-item-active-color !important;

            &:hover {
              color: adjust-color($color: $city-sidebar-dark-menu-item-color, $red: 150, $blue: 150, $green: 150) !important;
            }
          }
        }

        a {
          color: $city-sidebar-dark-menu-item-color !important;

          i {
            color: $city-sidebar-dark-menu-item-icon-color !important;
          }

          &:hover {
            background-color: darken($city-sidebar-dark-bg, 6%) !important;
            color: $city-sidebar-dark-menu-item-hover-color !important;

            i {
              color: $city-sidebar-dark-menu-item-hover-color !important;
            }
          }
        }

        >.mm-active {
          background-color: $city-sidebar-dark-menu-item-active-bg !important;
        }

        ul.sub-menu {
          li {
            a {
              color: $city-sidebar-dark-menu-sub-item-color !important;

              &:hover {
                color: $city-sidebar-dark-menu-item-hover-color !important;
              }
            }
          }
        }
      }

      >li {
        >a {
          &.mm-active {
            background-color: $city-sidebar-dark-menu-item-active-bg !important;
          }
        }
      }
    }
  }

  // Enlarge menu
  &.vertical-collpsed {

    // Side menu
    .vertical-menu {

      // Sidebar Menu
      #sidebar-menu {
        >ul {
          >li {
            &:hover {
              >a {
                background: lighten($city-sidebar-dark-bg, 2%) !important;
                color: $city-sidebar-dark-menu-item-hover-color !important;

                i {
                  color: $city-sidebar-dark-menu-item-hover-color !important;
                }
              }

              >ul {
                a {
                  color: $city-sidebar-dark-menu-sub-item-color !important;

                  &:hover {
                    color: $city-sidebar-dark-menu-item-hover-color !important;
                  }
                }
              }
            }
          }

          ul {
            background-color: darken($city-sidebar-dark-menu-item-active-color, 4%) !important;
          }
        }

        ul {
          li {
            &.mm-active .active {
              color: $city-sidebar-dark-menu-item-active-color !important;

              i {
                color: $city-sidebar-dark-menu-item-active-color !important;
              }
            }
          }
        }
      }
    }
  }

  .mm-active {
    color: $city-sidebar-dark-menu-item-active-color !important;

    >a {
      color: $city-sidebar-dark-menu-item-active-color !important;
      background-color: $city-sidebar-dark-menu-item-active-bg !important;

      i {
        color: $city-sidebar-dark-menu-item-active-color !important;
      }
    }

    >i {
      color: $city-sidebar-dark-menu-item-active-color !important;
    }

    .active {
      color: $city-sidebar-dark-menu-item-active-color !important;
      background-color: $city-sidebar-dark-menu-item-active-bg !important;

      i {
        color: $city-sidebar-dark-menu-item-active-color !important;
      }
    }
  }

  .menu-title {
    color: $city-sidebar-dark-menu-item-icon-color !important;
  }

  .ct-series-a {

    .ct-area,
    .ct-slice-pie {
      fill: $city-primary;
    }
  }

  .ct-chart {
    .ct-series {
      &.ct-series-a {

        .ct-bar,
        .ct-line,
        .ct-point,
        .ct-slice-donut {
          stroke: $city-primary;
        }
      }
    }
  }
}